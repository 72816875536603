/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { errorPopupWrapper } from '@/components/Error/errorPopupWrapper';
import { ErrorMessage } from '@/models/error/ErrorMessagesModels';
import { DeprecatedRightEnum } from '@/models/user/DeprecatedRightEnum';
import { RoleEnum } from '@/models/user/RoleEnum';
import { hasRole, hasRoles } from '@/models/user/User';
import { LazyState } from '@/utils/lazyState';
import { getAll as getAllAecSwProjects } from '@/modules/campaign_management/services/Aec/AecSoftwareProjectService';
import * as BigLoopReleasesService from '@/modules/campaign_management/services/BigLoop/BigLoopReleasesService';
import * as DrmReleaseService from '@/modules/campaign_management/services/Drm/DrmSoftwareProjectService';
import * as Oru20ReleasesService from '@/modules/campaign_management/services/Oru20/Oru20ReleasesService';

export const oru20Releases = new LazyState(
  errorPopupWrapper(Oru20ReleasesService.getAllReleases, [], ErrorMessage.CM_RELEASES_ORU20_GET),
).getReactiveWrapper();
export const bigLoopReleases = new LazyState(
  errorPopupWrapper(BigLoopReleasesService.getAllReleases, [], ErrorMessage.CM_RELEASES_BIGLOOP_GET),
).getReactiveWrapper();
export const drmSwProjects = new LazyState(
  errorPopupWrapper(DrmReleaseService.getAllSoftwareProjects, [], ErrorMessage.CM_SW_PROJECT_DRM_GET),
).getReactiveWrapper();
export const aecSwProjects = new LazyState(
  errorPopupWrapper(getAllAecSwProjects, [], ErrorMessage.CM_SW_PROJECT_AEC_GET),
).getReactiveWrapper();

export const hasCommonSwProjectRoles = () =>
  hasRoles([RoleEnum.UT_CU, RoleEnum.PERM_SW_PROJECT_READ]) ||
  hasRoles([RoleEnum.UT_DRM, RoleEnum.PERM_SW_PROJECT_READ]) ||
  hasRole(DeprecatedRightEnum.CM_E3_2_0_ORU_MANAGER);
